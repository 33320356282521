body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  font-size: 18px !important;
}

html {
  overflow-y: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img,
button,
svg,
select,
a {
  cursor: pointer;
}
li {
  list-style: none;
}

/* table */
.table-title {
  font-size: 22px;
}

.bottom_borderColor_overview {
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 8px !important;
  margin: 0 142px 15px 0;
  width: 50px !important;
}

th {
  padding: 0.3em 0.5em !important;
  white-space: nowrap;
  font-size: 18px;
}

.border {
  padding-right: 24px;
}

td {
  padding: 0.3em 0.5em !important;
  /* text-align: left !important; */
  white-space: nowrap;
  font-size: 16px;
}

.align_left {
  text-align: left;
}

.text-right.col button,
.cnclCols.col button {
  width: 150px;
}
.td-20 {
  width: 20%;
}

.dash {
  padding-left: 5rem !important;
  text-align: left;
}

.align_right {
  text-align: right;
}

.align_center {
  text-align: center;
}

.cell_width_25 {
  width: 25%;
}

.header {
  margin-top: 16px;
  text-align: left;
}

.backgroundColor {
  margin-top: 45px;
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 165px;
  font-size: 20px;
}

.table-link {
  color: #0dcaf0 !important;
  cursor: pointer;
}

/*-----------------------------*/
.activePagination p {
  background-color: #4bcbf0;
  width: 30px;
  color: white !important;
}

.page-link:hover {
  z-index: 2;
  background-color: #4bcbf0 !important;
  color: #dee2e6 !important;
}

li.page-item p {
  margin-top: 0;
  margin-bottom: 0;
  width: 30px;
  border-radius: 3px;
  color: black;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.pointerPageNumber {
  cursor: pointer !important;
  width: 15px;
  background-color: white;
}

.page-link {
  padding: 0.375rem 10px !important;
}

.makeStyles-root-26 {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.MuiCircularProgress-colorPrimary {
  color: #2897b7 !important;
}

li.page-item {
  padding: 10px;
}

.pagination {
  justify-content: center;
}

.makeStyles-drawerPaper-10 {
  width: 16.35% !important;
  border: hidden !important;
}

.ReactPaginate ul {
  display: flex;
  float: right !important;
  margin-top: 15px;
  justify-content: center;
}

.ReactPaginate ul li:not(:first-child):not(:last-child) {
  width: 30px;
  height: 34px;
  line-height: 2.2rem;
  margin: auto 6px;
  border: 1px solid #7f7c7c;
}

li.selected {
  border: 1px solid #4bcbf0 !important;
  background-color: #4bcbf0;
}

li.selected a {
  color: white;
  width: 30px;
  text-decoration: none;
}

.disabled {
  background-color: #d4d4d4 !important;
  color: #757575 !important;
}

.disable {
  opacity: 0.1;
  cursor: initial;
}

.archiveIcon {
  width: 35px;
  height: 26px;
  cursor: pointer;
}

.archiveIconHide {
  width: 35px;
  height: 26px;
  cursor: unset;
  border: none;
  background: transparent;
}

.archiveIconDisable {
  display: none;
  cursor: initial;
  width: 50px;
  height: 26px;
}

.unarchive {
  width: 53px;
  height: 26px;
  cursor: pointer;
}

.makeStyles-paper-1 {
  display: flex;
  margin-top: 30px !important;
  align-items: center;
  flex-direction: column;
}

.MuiContainer-root {
  margin-right: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: -9px;
  margin-left: 30px !important;
  margin-right: 0 !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.text-primary2 {
  color: #4bcbf0 !important;
}

.mx-2.mt-5.table-border {
  min-height: 45vh;
  padding-bottom: 10px;
}

.MuiTableContainer-root {
  width: 100%;
  overflow-x: clip;
}

.myPagination {
  display: flex;
  justify-content: flex-end;
}
.notFound {
  height: 35px;
  display: flex;
  align-items: center;
}
.noUserfound {
  font-size: 1.5rem;
  position: absolute;
  left: 60%;
  transform: translate(-60%, 0);
}

.viewIcon {
  height: 3rem !important;
  width: 3rem !important;
  color: #4bcbf0;
  cursor: pointer;
  margin-bottom: -5px;
}

.edit-icon {
  width: 20px;
  height: 27px;
  margin: 0px 4px -9px;
}

.delicon {
  width: 25px;
  height: 23px;
  margin: 0px 4px;
}

.MuiTablePagination-actions {
  color: blue;
}

.MuiTablePagination-actions:hover {
  color: red;
}

.MuiIconButton-root:hover {
  color: blue;
}

.table_container {
  width: 100%;
}

.invoice-bottom-table {
  padding: 0px 20px;
  margin-bottom: 20px;
}

input[disabled] {
  cursor: default;
  opacity: 0.7;
}

::placeholder {
  color: lightgrey;
}

.rows-select {
  padding: 4.5px 10px;
  margin: -5px 0 0 5px;
  font-size: 18px;
}

.errormsg {
  color: red;
  font-size: 14px;
  justify-content: left;
  display: flex;
  padding-left: 100px;
}

.MuiTablePagination-displayedRows {
  margin-top: 10px;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  font-size: 16px;
}

.actiondisable {
  color: red;
  cursor: initial;
}

.descriptionBox {
  cursor: pointer;
}

.submitbtndis {
  background-color: #95bfcf;
  color: white;
  border: none;
  height: 37px;
  width: 20%;
  font-size: 20px;
}

.submitbtn {
  background-color: #0bb1d3;
  color: white;
  border: none;
  height: 37px;
  width: 20%;
  font-size: 20px;
}

.field-logo {
  position: absolute;
}

.logo-img {
  height: 35px;
  margin-top: 6px;
  margin-left: -45px;
  width: 35px;
}

.con-img {
  height: 30px;
  margin-top: 8px;
  margin-left: -45px;
  width: 30px;
}

.approws {
  padding: 15px 200px;
}

.venrows {
  padding: 10px 200px;
}
.venrows1 {
  padding: 10px 200px 0px;
}
.venrows3 {
  padding: 10px 200px 5px;
}

.venrows2 {
  padding: 10px 200px 0px;
}
.form_row_btn {
  margin-top: 25px;
  justify-content: center;
  align-items: center;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "\f0c6";
  font-family: "FontAwesome";
  transform: rotate(-45deg);
  margin-right: 10px;
  font-size: x-large;
}

.filess {
  margin-top: 0 !important;
}

.custom-file-input {
  padding-top: 5px !important;
  border-radius: 5px !important;
}

.custom-file-label {
  height: calc(3.5rem + 2px) !important;
  text-align: left !important;
  overflow: hidden;
}

.custom-file-label::after {
  background-color: transparent;
  border-left: none !important;
}

.addEditbtn {
  background-color: #0dcaf0;
  color: white;
  border: none;
  height: 40px;
  padding: 0 15px;
  font-size: 18px;
}

.titlecol {
  text-align: left;
}

.btncol {
  text-align: end;
}

.boxborder {
  border: 1px solid #0dcaf0;
  border-radius: 1.5rem;
  padding: 1rem 2rem;
}
.div-add-user-button{
  display: flex;
  justify-content: end;
}
/* audit modal styling */
.modalcrossbutton{
  background-color: white;
  cursor: pointer;
  margin: 10px 20px;
}
.modalboxborder{
  border: 1px solid #0dcaf0;
  border-radius: 1.5rem;
  margin: 10px;
}
.modal-caption{
  margin-left: 5px; width: 500px; color: darkblue
}

.bigpartistes {
  width: 100% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
  padding-bottom: 2%;
}

.partistes {
  width: 81.5% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
  padding-bottom: 2%;
}

.tablebox {
  padding: 0 20px;
}

.rightIcon {
  height: 3rem !important;
  width: 3rem !important;
  color: green;
  cursor: pointer;
}

.wrongIcon {
  height: 3rem !important;
  width: 3rem !important;
  color: red;
  cursor: pointer;
}

.selectbox {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}

.pageLink {
  color: black;
}

.pageLink:hover {
  color: #0dcaf0;
  cursor: pointer;
}

.input-borders {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  font-size: 17px;
  font-family: inherit;
}

.loaderDiv {
  position: absolute;
  left: 100%;
  top: 100%;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .invoice-bottom-table {
    padding: 0px 5px;
  }

  .venrows {
    padding: 5px 25px;
  }
  .venrows1 {
    padding: 5px 25px 0px;
  }
  .venrows3 {
    padding: 5px 25px 3px;
  }
  .venrows2 {
    padding: 5px 25px 0px;
  }

  .approws {
    padding: 5px 25px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  th {
    font-size: 16px;
  }

  td {
    font-size: 14px;
  }

  .rows-select {
    padding: 2.5px 3px;
    font-size: 14px;
  }

  .boxborder {
    padding: 1.5rem;
  }

  .addEditbtn {
    height: 35px;
    padding: 0 10px;
    font-size: 16px;
  }

  .invoice-bottom-table {
    padding: 0px 5px;
  }

  .tablebox {
    padding: 0px 5px;
  }

  .venrows {
    padding: 5px 26px;
  }
  .venrows1 {
    padding: 5px 26px 0px;
  }
  .venrows2 {
    padding: 5px 26px 0px;
  }
  .venrows3 {
    padding: 5px 26px 3px;
  }
  
  .approws {
    padding: 5px 25px;
  }

  .logo-img {
    height: 30px;
    margin-top: 12px;
    margin-left: -37px;
    width: 30px;
  }

  .con-img {
    height: 24px;
    margin-top: 14px;
    margin-left: -39px;
    width: 26px;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  th {
    font-size: 14px;
  }

  td {
    font-size: 14px;
  }

  .rightIcon {
    width: 2.7rem !important;
  }

  .wrongIcon {
    width: 2.7rem !important;
  }

  .viewIcon {
    width: 2.7rem !important;
  }

  .archiveIcon {
    width: 26px;
    height: 20px;
  }

  .unarchive {
    width: 26px;
    height: 20px;
  }

  .addEditbtn {
    height: 30px;
    padding: 0 7px;
    font-size: 14px;
  }

  .bottom_borderColor_overview {
    margin: 0 81px 11px 0;
    width: 39px !important;
  }

  .boxborder {
    padding: 1.2rem;
  }

  .border {
    padding-right: 5px;
  }

  .selectbox {
    padding: 5px;
    margin-top: 3px;
  }

  .custom-file-label {
    height: 3.5rem !important;
  }

  .submitbtn {
    width: 35%;
    font-size: 18px;
  }

  .input-borders {
    padding: 5px;
    margin-top: 2px;
    font-size: 16px;
  }

  .delicon {
    width: 22px;
    height: 19px;
    margin: 0px 3px;
  }

  .edit-icon {
    width: 18px;
    height: 27px;
    margin: 0px 1px -10px;
  }

  .invoice-bottom-table {
    padding: 0px 1px;
  }

  .partistes {
    width: 100% !important;
  }

  .tablebox {
    padding: 0px 5px;
  }

  .venrows {
    padding: 5px 12px;
    display: grid;
  }
  .venrows1 {
    padding: 5px 12px 0px ;
    display: grid;
  }
  .venrows3 {
    padding: 5px 12px 3px ;
    display: grid;
  }
  .venrows2 {
    padding: 5px 12px 0px ;
    display: grid;
  }

  .logo-img {
    height: 25px;
    margin-top: 6px;
    margin-left: -40px;
    width: 25px;
  }

  .con-img {
    height: 22px;
    margin-top: 8px;
    margin-left: -40px;
    width: 20px;
  }

  .contactNo {
    margin-top: 10px;
  }

  .approws {
    padding: 5px 15px;
    display: grid;
  }
  .custom-file {
    margin-top: 10px;
  
  }

  
}
