.table {
    width: 98%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.filter-btn {
    text-align: end;
    padding-right: 20px;
}

.userfilter-btns {
    text-align: end;
    padding-right: 20px;
    margin-top: -23px;
}

.select-box {
    margin-top: 10px;
    border: 1px solid #a2afb4;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    outline: none;
}

.select-boxstatus {
    margin-top: -3px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    width: 100%;
    padding: 1rem;
    outline: none !important;
    width: 105px;

}

.modal-btn {
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #0dcaf0;
    border-radius: 15rem;
    color: white;
    border: none;
    height: 37px;
    width: 100%;
}

.undoicon {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.backgroundColor123 {
    margin-top: 45px;
    background-color: #0dcaf0;
    border-radius: 15rem;
    color: white;
    border: none;
    height: 37px;
    width: 116px;
}

.firstcol {
    text-align: right;
}

.secondcol {
    text-align: left;
}

.bg-light {
    height: 74px;
}

.fa-bell-o {
    margin-right: 2rem;
}


.userClass {
    padding-right: 25px;
    padding-bottom: 2px;

}

.partition11 {
    width: 82.5% !important;
    float: right;
    overflow-x: hidden !important;
}

.table-link {
    color: #0dcaf0 !important;
    cursor: pointer;
}

.table-borders {
    border: 1px solid rgba(13, 202, 240, .3);
    border-radius: 1.5rem;
    padding-left: 32px;
    padding-right: 32px;
}

.pro-finance-partition {
    width: 81.5% !important;
    float: right;
    transition: 0.5s;
    overflow-x: hidden !important;
}

.finance-partition {
    width: 100% !important;
    transition: 0.5s;
    float: right;
    overflow-x: hidden !important;
}

.makeStyles-drawerPaper-6 {
    width: 15%;
    overflow-x: clip;
}

.makeStyles-drawerPaper-10 {
    width: 15%;
}

.hr-modal {
    height: 6px !important;
    width: 39px;
}

.w-25 {
    width: 25%;
}

.makeStyles-root-5 {
    display: flex;
    z-index: 0;
    padding-top: 24px;

}

.jZkBQk .wrapper {
    padding: 0 !important;
}

.invoices-top-table {
    margin-bottom: -10px;
    padding-right: 30px;
}

.width {
    width: 150px;
}

.wrapper {
    text-align: center;
}

.table -width {
    width: 97% !important;
}

.table-header-custom {
    width: fit-content;
    text-align: left;
}

.subHeading {
    display: inline-block;
    width: '35%';
}

.modal-content {
    position: fixed !important;
    top: 50% !important;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid;
}

.archivedBtn {
    padding: 0;
    background-color: transparent;
    border: none;
}

.close-btn {
    /* position: absolute;
    top: 10px;
    right: 10px; */
    font: 900;
    border: none;
    background: transparent;

}
.close-btn svg {
       font-size:30px;
}

.archiveHeader {
    display: flex;
    justify-content: space-between;
}

.datecurve{
    border-radius: 20px;
    border: 1px solid #dfeae6;
    padding: 0.8rem 1.2rem;
    color: black;
    width: 31%;
    outline: none;
    font-size: 18px;
    height: 40px;
}

@media screen and (min-width:320px) and (max-width:481px) {
    .pro-finance-partition {
        width: 99% !important;
    }

    .finance-partition {
        width: 99% !important;
    }
}

.overduedatepaidmodalm{
    height: auto;
}

.error-reason{
color: red !important;
font-size: 14px;
}

/* .reasonmodal{
  width:40% !important;
  text-align: center;
  background-color: #fff;
} */

.lblreason{
    /* background-color:blue !important ; */
    width: 100%;
    text-align: center;
 
    
}
.lbltext{
  font-size: 17px;
}

.reasonmodal{
    position: absolute;
      top: 27rem;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 0px solid #000;
      background-color: white;
      box-shadow: 10;
      border-radius: 20px;
      padding: 30px;
     width: 400px;
        height: 300px;
    }
    .textpartial:hover {
    /* opacity: 0.8; */
    color: #52595D;
    }

.btnproceed{
    background-color: #0dcaf0;
    color:white !important;
    outline:none;
    /* margin-right: 10px; */
}
.btnpro{
border-radius: 5px !important;
width: 100px !important;   
}
.btnproceed:hover{
    opacity: 0.7;
}
.proceeinvoiceBtn{
    text-align: right;
}

.bulk-invoice-input-fields {
    width: 170px;
    height: 30px;
    border-radius: 7px;
    padding: 5px;
}

.bulk-payment-textarea-field {
    min-height: 30px;
    min-width: 170px;
    max-width: 340px;
    max-height: 90px;
    resize: both;
    overflow: auto;
}

.bulk-invoice-errors {
    color: orangered;
    font-size: 12px;
    margin: 0;
}

.bulk-payment-textarea-error{
    margin-top: -5px;
}

.main-table {
    background-color: white;
}
.invoice-count{
 color:#0DCAF0;
 font-size: 15px;
  background-color:#fff;
  border: 1px solid #0DCAF0;
  border-radius: 5px !important;
  min-width:100px !important;
  width:auto !important;
  height: 33px !important;
 padding-top: 2px !important;
 display: flex;
 justify-content: center;
 align-items: center;

}
.invoice-count-col{
   display: flex;
   justify-content: flex-end;
}

.back-styles {
    display: flex;
    align-items: center;
    gap: 10px;
}
.continueadding{
   width: 127px !important;
   
}
.autoincheightreason{
    width: 200px;
    /* padding: 5px; */
   border-radius: 5px;
    border: 1px solid #b7c4c9;
   overflow-y: hidden;
    /* margin-top: 7px; */
    min-height:30px !important;
 /* resize:vertical; */
 resize:none;
 height:30px;
 max-height: 300px;
}
.downloadcsv{
    height:30px;
    width:30px;
  }
  .csvtitle{
    text-align: center;
  }

  .disablerejectpay{
    color:#B3E7FE;
  }
  .payment-error{
    color: red;
     font-size: 14px;
  }