@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
  font-size: 18px !important;
}

html {
  overflow-y: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img,
button,
svg,
select,
a {
  cursor: pointer;
}
li {
  list-style: none;
}

/* table */
.table-title {
  font-size: 22px;
}

.bottom_borderColor_overview {
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 8px !important;
  margin: 0 142px 15px 0;
  width: 50px !important;
}

th {
  padding: 0.3em 0.5em !important;
  white-space: nowrap;
  font-size: 18px;
}

.border {
  padding-right: 24px;
}

td {
  padding: 0.3em 0.5em !important;
  /* text-align: left !important; */
  white-space: nowrap;
  font-size: 16px;
}

.align_left {
  text-align: left;
}

.text-right.col button,
.cnclCols.col button {
  width: 150px;
}
.td-20 {
  width: 20%;
}

.dash {
  padding-left: 5rem !important;
  text-align: left;
}

.align_right {
  text-align: right;
}

.align_center {
  text-align: center;
}

.cell_width_25 {
  width: 25%;
}

.header {
  margin-top: 16px;
  text-align: left;
}

.backgroundColor {
  margin-top: 45px;
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 165px;
  font-size: 20px;
}

.table-link {
  color: #0dcaf0 !important;
  cursor: pointer;
}

/*-----------------------------*/
.activePagination p {
  background-color: #4bcbf0;
  width: 30px;
  color: white !important;
}

.page-link:hover {
  z-index: 2;
  background-color: #4bcbf0 !important;
  color: #dee2e6 !important;
}

li.page-item p {
  margin-top: 0;
  margin-bottom: 0;
  width: 30px;
  border-radius: 3px;
  color: black;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.pointerPageNumber {
  cursor: pointer !important;
  width: 15px;
  background-color: white;
}

.page-link {
  padding: 0.375rem 10px !important;
}

.makeStyles-root-26 {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.MuiCircularProgress-colorPrimary {
  color: #2897b7 !important;
}

li.page-item {
  padding: 10px;
}

.pagination {
  justify-content: center;
}

.makeStyles-drawerPaper-10 {
  width: 16.35% !important;
  border: hidden !important;
}

.ReactPaginate ul {
  display: flex;
  float: right !important;
  margin-top: 15px;
  justify-content: center;
}

.ReactPaginate ul li:not(:first-child):not(:last-child) {
  width: 30px;
  height: 34px;
  line-height: 2.2rem;
  margin: auto 6px;
  border: 1px solid #7f7c7c;
}

li.selected {
  border: 1px solid #4bcbf0 !important;
  background-color: #4bcbf0;
}

li.selected a {
  color: white;
  width: 30px;
  text-decoration: none;
}

.disabled {
  background-color: #d4d4d4 !important;
  color: #757575 !important;
}

.disable {
  opacity: 0.1;
  cursor: initial;
}

.archiveIcon {
  width: 35px;
  height: 26px;
  cursor: pointer;
}

.archiveIconHide {
  width: 35px;
  height: 26px;
  cursor: unset;
  border: none;
  background: transparent;
}

.archiveIconDisable {
  display: none;
  cursor: initial;
  width: 50px;
  height: 26px;
}

.unarchive {
  width: 53px;
  height: 26px;
  cursor: pointer;
}

.makeStyles-paper-1 {
  display: flex;
  margin-top: 30px !important;
  align-items: center;
  flex-direction: column;
}

.MuiContainer-root {
  margin-right: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: -9px;
  margin-left: 30px !important;
  margin-right: 0 !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.text-primary2 {
  color: #4bcbf0 !important;
}

.mx-2.mt-5.table-border {
  min-height: 45vh;
  padding-bottom: 10px;
}

.MuiTableContainer-root {
  width: 100%;
  overflow-x: clip;
}

.myPagination {
  display: flex;
  justify-content: flex-end;
}
.notFound {
  height: 35px;
  display: flex;
  align-items: center;
}
.noUserfound {
  font-size: 1.5rem;
  position: absolute;
  left: 60%;
  -webkit-transform: translate(-60%, 0);
          transform: translate(-60%, 0);
}

.viewIcon {
  height: 3rem !important;
  width: 3rem !important;
  color: #4bcbf0;
  cursor: pointer;
  margin-bottom: -5px;
}

.edit-icon {
  width: 20px;
  height: 27px;
  margin: 0px 4px -9px;
}

.delicon {
  width: 25px;
  height: 23px;
  margin: 0px 4px;
}

.MuiTablePagination-actions {
  color: blue;
}

.MuiTablePagination-actions:hover {
  color: red;
}

.MuiIconButton-root:hover {
  color: blue;
}

.table_container {
  width: 100%;
}

.invoice-bottom-table {
  padding: 0px 20px;
  margin-bottom: 20px;
}

input[disabled] {
  cursor: default;
  opacity: 0.7;
}

::-webkit-input-placeholder {
  color: lightgrey;
}

::placeholder {
  color: lightgrey;
}

.rows-select {
  padding: 4.5px 10px;
  margin: -5px 0 0 5px;
  font-size: 18px;
}

.errormsg {
  color: red;
  font-size: 14px;
  justify-content: left;
  display: flex;
  padding-left: 100px;
}

.MuiTablePagination-displayedRows {
  margin-top: 10px;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  font-size: 16px;
}

.actiondisable {
  color: red;
  cursor: initial;
}

.descriptionBox {
  cursor: pointer;
}

.submitbtndis {
  background-color: #95bfcf;
  color: white;
  border: none;
  height: 37px;
  width: 20%;
  font-size: 20px;
}

.submitbtn {
  background-color: #0bb1d3;
  color: white;
  border: none;
  height: 37px;
  width: 20%;
  font-size: 20px;
}

.field-logo {
  position: absolute;
}

.logo-img {
  height: 35px;
  margin-top: 6px;
  margin-left: -45px;
  width: 35px;
}

.con-img {
  height: 30px;
  margin-top: 8px;
  margin-left: -45px;
  width: 30px;
}

.approws {
  padding: 15px 200px;
}

.venrows {
  padding: 10px 200px;
}
.venrows1 {
  padding: 10px 200px 0px;
}
.venrows3 {
  padding: 10px 200px 5px;
}

.venrows2 {
  padding: 10px 200px 0px;
}
.form_row_btn {
  margin-top: 25px;
  justify-content: center;
  align-items: center;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "\f0c6";
  font-family: "FontAwesome";
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 10px;
  font-size: x-large;
}

.filess {
  margin-top: 0 !important;
}

.custom-file-input {
  padding-top: 5px !important;
  border-radius: 5px !important;
}

.custom-file-label {
  height: calc(3.5rem + 2px) !important;
  text-align: left !important;
  overflow: hidden;
}

.custom-file-label::after {
  background-color: transparent;
  border-left: none !important;
}

.addEditbtn {
  background-color: #0dcaf0;
  color: white;
  border: none;
  height: 40px;
  padding: 0 15px;
  font-size: 18px;
}

.titlecol {
  text-align: left;
}

.btncol {
  text-align: end;
}

.boxborder {
  border: 1px solid #0dcaf0;
  border-radius: 1.5rem;
  padding: 1rem 2rem;
}
.div-add-user-button{
  display: flex;
  justify-content: end;
}
/* audit modal styling */
.modalcrossbutton{
  background-color: white;
  cursor: pointer;
  margin: 10px 20px;
}
.modalboxborder{
  border: 1px solid #0dcaf0;
  border-radius: 1.5rem;
  margin: 10px;
}
.modal-caption{
  margin-left: 5px; width: 500px; color: darkblue
}

.bigpartistes {
  width: 100% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
  padding-bottom: 2%;
}

.partistes {
  width: 81.5% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
  padding-bottom: 2%;
}

.tablebox {
  padding: 0 20px;
}

.rightIcon {
  height: 3rem !important;
  width: 3rem !important;
  color: green;
  cursor: pointer;
}

.wrongIcon {
  height: 3rem !important;
  width: 3rem !important;
  color: red;
  cursor: pointer;
}

.selectbox {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}

.pageLink {
  color: black;
}

.pageLink:hover {
  color: #0dcaf0;
  cursor: pointer;
}

.input-borders {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  font-size: 17px;
  font-family: inherit;
}

.loaderDiv {
  position: absolute;
  left: 100%;
  top: 100%;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .invoice-bottom-table {
    padding: 0px 5px;
  }

  .venrows {
    padding: 5px 25px;
  }
  .venrows1 {
    padding: 5px 25px 0px;
  }
  .venrows3 {
    padding: 5px 25px 3px;
  }
  .venrows2 {
    padding: 5px 25px 0px;
  }

  .approws {
    padding: 5px 25px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  th {
    font-size: 16px;
  }

  td {
    font-size: 14px;
  }

  .rows-select {
    padding: 2.5px 3px;
    font-size: 14px;
  }

  .boxborder {
    padding: 1.5rem;
  }

  .addEditbtn {
    height: 35px;
    padding: 0 10px;
    font-size: 16px;
  }

  .invoice-bottom-table {
    padding: 0px 5px;
  }

  .tablebox {
    padding: 0px 5px;
  }

  .venrows {
    padding: 5px 26px;
  }
  .venrows1 {
    padding: 5px 26px 0px;
  }
  .venrows2 {
    padding: 5px 26px 0px;
  }
  .venrows3 {
    padding: 5px 26px 3px;
  }
  
  .approws {
    padding: 5px 25px;
  }

  .logo-img {
    height: 30px;
    margin-top: 12px;
    margin-left: -37px;
    width: 30px;
  }

  .con-img {
    height: 24px;
    margin-top: 14px;
    margin-left: -39px;
    width: 26px;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  th {
    font-size: 14px;
  }

  td {
    font-size: 14px;
  }

  .rightIcon {
    width: 2.7rem !important;
  }

  .wrongIcon {
    width: 2.7rem !important;
  }

  .viewIcon {
    width: 2.7rem !important;
  }

  .archiveIcon {
    width: 26px;
    height: 20px;
  }

  .unarchive {
    width: 26px;
    height: 20px;
  }

  .addEditbtn {
    height: 30px;
    padding: 0 7px;
    font-size: 14px;
  }

  .bottom_borderColor_overview {
    margin: 0 81px 11px 0;
    width: 39px !important;
  }

  .boxborder {
    padding: 1.2rem;
  }

  .border {
    padding-right: 5px;
  }

  .selectbox {
    padding: 5px;
    margin-top: 3px;
  }

  .custom-file-label {
    height: 3.5rem !important;
  }

  .submitbtn {
    width: 35%;
    font-size: 18px;
  }

  .input-borders {
    padding: 5px;
    margin-top: 2px;
    font-size: 16px;
  }

  .delicon {
    width: 22px;
    height: 19px;
    margin: 0px 3px;
  }

  .edit-icon {
    width: 18px;
    height: 27px;
    margin: 0px 1px -10px;
  }

  .invoice-bottom-table {
    padding: 0px 1px;
  }

  .partistes {
    width: 100% !important;
  }

  .tablebox {
    padding: 0px 5px;
  }

  .venrows {
    padding: 5px 12px;
    display: grid;
  }
  .venrows1 {
    padding: 5px 12px 0px ;
    display: grid;
  }
  .venrows3 {
    padding: 5px 12px 3px ;
    display: grid;
  }
  .venrows2 {
    padding: 5px 12px 0px ;
    display: grid;
  }

  .logo-img {
    height: 25px;
    margin-top: 6px;
    margin-left: -40px;
    width: 25px;
  }

  .con-img {
    height: 22px;
    margin-top: 8px;
    margin-left: -40px;
    width: 20px;
  }

  .contactNo {
    margin-top: 10px;
  }

  .approws {
    padding: 5px 15px;
    display: grid;
  }
  .custom-file {
    margin-top: 10px;
  
  }

  
}


.errorclass{
  position: fixed;
  color: red;
  font-size: 15px;
  text-align: left;
  
   width: 100% 
}

.otpbtn{
  color: white;
  background-color: #64bcec;
  border: none;
  font-size: 15px;
  padding: 6px 0px;
  margin-top: 0px;
  width: 400px;
 
  text-align: center;
}

.otp{
  text-align: center;
  margin: 15px px;
  color: black;
  font-weight: 600;
}
.inputOtp{
display: flex;
margin-top: 15px;
justify-content: center;
}
.pass{
  
  width: 45px;
  margin: 8px 10px;
  text-align: center;

}
.email-text{
  font-weight: 300;
  font-size: medium;}

.background-img {
  background-image: url(/static/media/Login.7d40ee7c.png);
  position: relative;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}


.loginPageOuterDiv {
  width: 90%;
  margin-left: 10px;
  margin-top: 6rem;
}

.pwds-container {
  position: relative;
  padding: 1.3rem 0 0;
}

.pwds-container img {
  cursor: pointer;
  position: absolute;
  width: 28px;
  right: 10px;
  top: 2rem;
}

.fieldspace {
  padding: 1.3rem 0;
}

.text {
  background: "white"
}


.size {
  font-size: 35px;
}


img.pb-1.logo {
  width: 20rem;
  margin: auto;
}


.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: -25px !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-align: right;
}

.link-text {
  font-size: 1.4rem;
  color: black;
  font-weight: 100;
}

.link-text:hover {
  color: black;
}

span.MuiButton-label {
  font-size: 13px;
}

.bucket {
  margin-top: 50%;
  grid-column: 2/4;
}

a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.link-text.MuiTypography-body2.MuiTypography-colorPrimary {
  color: black;
}


.RememberMe {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding-bottom: 12px;
}

.remember {
  grid-column: 1/2;
  align-items: center;
  display: grid;
  grid-template-columns: 2rem 10rem;
}

.RememberText {
  margin-left: -20px !important;
}

.formclass {
  padding: 1rem 1rem;
}

.remeberBox {
  margin-top: 0px !important;
  align-self: center;
  justify-self: center;
}

.rememberLabel {
  color: black;
  font-size: 1.4rem;
  font-weight: 100;
  padding-left: 5px;
  justify-self: baseline;
  margin-bottom: 0px;
}

.login-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 17px;
  font-family:inherit;
}

.forgot {
  justify-self: end;
}

@media screen and (min-width:769px) and (max-width:1024px) {
  .login-row {
    grid-template-columns: repeat(8, 1fr);
  }

  .bucket {
    margin-top: 40%;
    grid-column: 2/5;
  }
  .errorclass{

  }
}

@media screen and (min-width:481px) and (max-width:768px) {
  .background-img {
    background-image: url("/static/media/backgroundTab (1).af16df5d.png");
    position: relative;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    overflow-x: overlay;
    background-size: 100%;
  }
  .login-row {
    grid-template-columns: repeat(5, 1fr);
  }

  .bucket {
    margin-top: 90%;
    grid-column: 2/5;
  }
  .login-input{
    padding: 3px;
  }
  img.pb-1.logo {
    width: 18rem;
  }
  .pwds-container img{
    top: 1.6rem;
  }
  .errorclass{
width: 250px;
    text-align: left;
  }
}

@media screen and (min-width:320px) and (max-width:481px) {
  .background-img {
    background-image: url(/static/media/backgorundmobile.fece8d82.png);
    position: relative;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    overflow-x: overlay;
    background-size: 100%;
  }
  .login-row {
    grid-template-columns: repeat(6, 1fr);
  }
  .bucket {
    margin-top: 25rem;
    grid-column: 1/-1;
  }
  img.pb-1.logo {
    width: 18rem;
  }
  .rememberLabel {
    font-size: 1.2rem;
  }
  .link-text {
    font-size: 1.2rem;
  }
  .login-input {
    padding: 2px;
  }
  .pwds-container img{
    width: 25px;
    right: 6px;
    top: 1.7rem;
  }
  .errorclass{
    margin-left: 157px
  }
  
}
.MyLoader {
  position: absolute;
  left: 50%;
  top: 50%;
}

.MyMyLoaderOuter,
.MyMyLoaderMiddle,
.MyMyLoaderInner {
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-right-color: #3cefff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.MyMyLoaderOuter {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

.MyMyLoaderMiddle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.MyMyLoaderInner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  -webkit-animation: spin 1.5s linear infinite;
          animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.table {
    width: 98%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.filter-btn {
    text-align: end;
    padding-right: 20px;
}

.userfilter-btns {
    text-align: end;
    padding-right: 20px;
    margin-top: -23px;
}

.select-box {
    margin-top: 10px;
    border: 1px solid #a2afb4;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
    outline: none;
}

.select-boxstatus {
    margin-top: -3px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    width: 100%;
    padding: 1rem;
    outline: none !important;
    width: 105px;

}

.modal-btn {
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #0dcaf0;
    border-radius: 15rem;
    color: white;
    border: none;
    height: 37px;
    width: 100%;
}

.undoicon {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.backgroundColor123 {
    margin-top: 45px;
    background-color: #0dcaf0;
    border-radius: 15rem;
    color: white;
    border: none;
    height: 37px;
    width: 116px;
}

.firstcol {
    text-align: right;
}

.secondcol {
    text-align: left;
}

.bg-light {
    height: 74px;
}

.fa-bell-o {
    margin-right: 2rem;
}


.userClass {
    padding-right: 25px;
    padding-bottom: 2px;

}

.partition11 {
    width: 82.5% !important;
    float: right;
    overflow-x: hidden !important;
}

.table-link {
    color: #0dcaf0 !important;
    cursor: pointer;
}

.table-borders {
    border: 1px solid rgba(13, 202, 240, .3);
    border-radius: 1.5rem;
    padding-left: 32px;
    padding-right: 32px;
}

.pro-finance-partition {
    width: 81.5% !important;
    float: right;
    transition: 0.5s;
    overflow-x: hidden !important;
}

.finance-partition {
    width: 100% !important;
    transition: 0.5s;
    float: right;
    overflow-x: hidden !important;
}

.makeStyles-drawerPaper-6 {
    width: 15%;
    overflow-x: clip;
}

.makeStyles-drawerPaper-10 {
    width: 15%;
}

.hr-modal {
    height: 6px !important;
    width: 39px;
}

.w-25 {
    width: 25%;
}

.makeStyles-root-5 {
    display: flex;
    z-index: 0;
    padding-top: 24px;

}

.jZkBQk .wrapper {
    padding: 0 !important;
}

.invoices-top-table {
    margin-bottom: -10px;
    padding-right: 30px;
}

.width {
    width: 150px;
}

.wrapper {
    text-align: center;
}

.table -width {
    width: 97% !important;
}

.table-header-custom {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: left;
}

.subHeading {
    display: inline-block;
    width: '35%';
}

.modal-content {
    position: fixed !important;
    top: 50% !important;
    left: 50%;
    width: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 1px solid;
}

.archivedBtn {
    padding: 0;
    background-color: transparent;
    border: none;
}

.close-btn {
    /* position: absolute;
    top: 10px;
    right: 10px; */
    font: 900;
    border: none;
    background: transparent;

}
.close-btn svg {
       font-size:30px;
}

.archiveHeader {
    display: flex;
    justify-content: space-between;
}

.datecurve{
    border-radius: 20px;
    border: 1px solid #dfeae6;
    padding: 0.8rem 1.2rem;
    color: black;
    width: 31%;
    outline: none;
    font-size: 18px;
    height: 40px;
}

@media screen and (min-width:320px) and (max-width:481px) {
    .pro-finance-partition {
        width: 99% !important;
    }

    .finance-partition {
        width: 99% !important;
    }
}

.overduedatepaidmodalm{
    height: auto;
}

.error-reason{
color: red !important;
font-size: 14px;
}

/* .reasonmodal{
  width:40% !important;
  text-align: center;
  background-color: #fff;
} */

.lblreason{
    /* background-color:blue !important ; */
    width: 100%;
    text-align: center;
 
    
}
.lbltext{
  font-size: 17px;
}

.reasonmodal{
    position: absolute;
      top: 27rem;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border: 0px solid #000;
      background-color: white;
      box-shadow: 10;
      border-radius: 20px;
      padding: 30px;
     width: 400px;
        height: 300px;
    }
    .textpartial:hover {
    /* opacity: 0.8; */
    color: #52595D;
    }

.btnproceed{
    background-color: #0dcaf0;
    color:white !important;
    outline:none;
    /* margin-right: 10px; */
}
.btnpro{
border-radius: 5px !important;
width: 100px !important;   
}
.btnproceed:hover{
    opacity: 0.7;
}
.proceeinvoiceBtn{
    text-align: right;
}

.bulk-invoice-input-fields {
    width: 170px;
    height: 30px;
    border-radius: 7px;
    padding: 5px;
}

.bulk-payment-textarea-field {
    min-height: 30px;
    min-width: 170px;
    max-width: 340px;
    max-height: 90px;
    resize: both;
    overflow: auto;
}

.bulk-invoice-errors {
    color: orangered;
    font-size: 12px;
    margin: 0;
}

.bulk-payment-textarea-error{
    margin-top: -5px;
}

.main-table {
    background-color: white;
}
.invoice-count{
 color:#0DCAF0;
 font-size: 15px;
  background-color:#fff;
  border: 1px solid #0DCAF0;
  border-radius: 5px !important;
  min-width:100px !important;
  width:auto !important;
  height: 33px !important;
 padding-top: 2px !important;
 display: flex;
 justify-content: center;
 align-items: center;

}
.invoice-count-col{
   display: flex;
   justify-content: flex-end;
}

.back-styles {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
.continueadding{
   width: 127px !important;
   
}
.autoincheightreason{
    width: 200px;
    /* padding: 5px; */
   border-radius: 5px;
    border: 1px solid #b7c4c9;
   overflow-y: hidden;
    /* margin-top: 7px; */
    min-height:30px !important;
 /* resize:vertical; */
 resize:none;
 height:30px;
 max-height: 300px;
}
.downloadcsv{
    height:30px;
    width:30px;
  }
  .csvtitle{
    text-align: center;
  }

  .disablerejectpay{
    color:#B3E7FE;
  }
  .payment-error{
    color: red;
     font-size: 14px;
  }
.form_row {
  margin-top: 3rem;
}

.downviewicon {
  text-align: center;
}

.icons {
  display: flex;
}

.modal-title {
  font-size: 23px !important;
  text-align: left !important;
}
.adduserBox {
  width: 750px;
}

.invoiceBox {
  width: 800px;
}

.viewBox {
  width: 590px;
}

.modalViewBox{
  width: calc(100% - 50px);
  max-height: 500px;
  overflow-y: scroll;
}

.rejctBox {
  width: 345px;
}

.delBox {
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 30px;
}
.deletebox {
  width: 280px;
}

.label-name {
  font-size: 16px;
  font-weight: normal;
}

.inputborder {
  width: 47%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  margin-top: 7px;
}

.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.input-borders::after {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  height: 95px;
  overflow: scroll;
}

.selectInput {
  width: 100%;
}

.input-area {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  overflow-y: scroll;
  margin-top: 7px;
  height: 50px;
  min-height: 50px !important;
  resize: vertical;
}

.add-btns {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.add-btns20 {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.cancel-btns {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.cancel-btns20 {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  padding: 0 30px;
  font-size: 20px;
}

.form-label {
  text-align: left;
}

.addRole {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.addCncl {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.addRole {
  margin-right: 5%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.addCncl {
  margin-right: 5%;
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 90%;
}

.del-logo {
  width: 50px;
  height: 50px;
  margin-left: 8.5rem;
}

.del-title {
  text-align: center;
  font-size: 1rem;
}

.del-hr {
  margin-left: 86px;
}

.del_row {
  margin-top: 1.9rem;
}

.delete-message {
  text-align: center;
  margin: 1rem !important;
  font-size: 20px !important;
}

.del-add {
  margin-right: -3%;
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  font-size: 20px;
}

.del-cncl {
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  font-size: 20px;
}

.viewCncl-btn {
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 40%;
  font-size: 18px;
}

.viewRow {
  margin-top: 1.8rem;
}

.error {
  font-size: 1rem;
  color: rgb(238, 73, 73);
}

.invView {
  width: 48%;
  cursor: pointer;
}

.modalclass {
  position: absolute;
  top: 27rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  margin-top: 10vh;
  padding: 30px;
}

.modalclass30{
  position: absolute;
  top: 27rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 5px;
}

.modalclass1 {
  position: absolute;
  top: 33rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  padding: 30px;
}

.input-field {
  width: 100%;
  border: 2px solid black;
}

.modalbox {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 95%;
}
.ppendingbox {
  width: 550px;
}

.closeIcn {
  text-align: end;
}

.closeCurs {
  cursor: pointer;
}

.modaltd {
  min-width: 0px !important;
}

.countmodal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 25px;
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
}

.changeModal {
  position: absolute;
  top: 30rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  border-radius: 20px;
  width: 319px;
  padding: 20px;
}

.personalInfo {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 0px solid #000;
  background-color: white;
  box-shadow: 10;
  width: 600px;
  padding: 15px 40px !important;
  border-radius: 20px;
}

.personalModal {
  position: absolute;
  top: 20%;
  left: 29%;
  width: 573px;
}

.files {
  width: 100%;
  margin-top: 7px;
  border-radius: 20px !important;
  box-shadow: 2px 2px 1px lightgray;
  border: 1px solid #b7c4c9;
}

::-webkit-file-upload-button {
  border: none;
  background-color: #d9d9f1;
  border-radius: 20px;
  height: 35px;
  cursor: pointer;
}

.input-borders.row {
  margin-left: 0rem;
}

.rejectmsg {
  border-radius: 5px;
  border: 1px solid #b7c4c9;
  margin-top: 0px;
  width: 100%;
  resize: none;
}

.addBtn {
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  margin-left: 14rem;
  margin-top: 5px;
  padding: 0 20px 0 20px;
}

.addReject {
  background-color: #0bb1d3;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  margin-top: 5px;
  padding: 0 20px 0 20px;
  font-size: 18px;
}

.cnclReject {
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  margin-top: 5px;
  font-size: 18px;
  padding: 0 20px 0 20px;
}

.cnclBtn {
  background-color: #9fa4a5;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  margin-left: 40px;
  margin-top: 5px;
  padding: 0 20px 0 20px;
}

.files input {
  opacity: 0;
  z-index: 99;
  display: block;
  cursor: pointer;
}

.symbol {
  width: 3.5rem;
}

.downloadIcon {
  height: 25px !important;
  width: 25px !important;
  color: #4bcbf0;
  cursor: pointer;
}

.view {
  height: 25px !important;
  width: 25px !important;
  color: #4bcbf0;
  cursor: pointer;
}

b.invoice {
  margin-left: -500px;
}

b.invoice1 {
  margin-left: -533px;
  font-size: 1.9rem;
}

.error-para {
  color: red;
  font-size: small;
  position: fixed;
}

.mandatory {
  color: red;
}

.AddBtn {
  margin-left: 144px;
  background-color: #0bb1d3 !important;
  font-size: 18px;
  margin-left: 24px;
}

.CancelBtn {
  margin-left: 10px;
  background-color: #0bb1d3 !important;
  font-size: 18px;
}

label.form-label {
  font-size: 16px;
  text-align: left !important;
  margin-top: 12px;
}

.pwd-container {
  position: relative;
}

.pwd-container input {
  width: 100%;
  font-size: 17px;
  padding-left: 8px;
  font-family: inherit;
}

.pwd-container img {
  cursor: pointer;
  position: relative;
  width: 26px;
  right: 8px;
  top: -1px;
}

.personalCncl {
  background-color: #0bb1d3;
  border: none;
  padding: 7px 22px;
  margin-left: 198px;
  margin-top: 15px;
  color: white;
}
.adminVenBox3{
  width: 600px;
  height: auto;
}
.adminVenBox2 {
  padding: 0 15px 0 0;
  width: 600px;
  height: 70vh;
  overflow: scroll;
}
.close-btn-div{
  display: flex;
    justify-content: flex-end;
}
.tabbox {
  font-size: large !important;
}

.select-react {
  padding-top: 7px;
}

.documents {
  color: #445ff7;
  text-decoration: underline;
  cursor: pointer;
}

.error-msg {
  color: red;
  font-size: 14px;
  margin-top: -11px;
}

.change_row.row {
  padding-left: 20px;
}

.topviewrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.personalInfo {
  padding: 2% 4%;
}

.h3,
h3 {
  font-size: 24px;
  margin-left: -331px;
}

.label-name12 {
  margin-left: -12px;
  font-size: 18px;
}

input#oldpassword {
  margin-bottom: 16px;
  height: 40px;
}

input#newpass {
  margin-bottom: 16px;
  height: 40px;
}

input#confirmpass {
  height: 40px;
}

.viewName {
  font-weight: 600;
}

.lastrow {
  margin-bottom: 1rem;
}

.supprottxt {
  font-weight: 600;
}

.adminVenBox {
  width: 700px;
  height: auto;
  overflow-y: hidden;
}
.adminVenBox1{
  width: 700px;
  height: auto;
  overflow-y: auto;
}

.add-vendor-error-text {
  color: red;
  font-size: 14px;
}

.add-vendor-modal {
  max-height: 60vh;
  overflow-y: auto;
}


@media screen and (min-width: 1025px) and (max-width: 1200px) {
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .modal-title {
    font-size: 20px !important;
    text-align: left !important;
  }
  .viewBox {
    width: 408px;
    height: 443px;
    overflow-y: auto;
  }
  .modalclass {
    padding: 25px;
  }
  .modalclass1 {
    padding: 25px;
  }
  .viewRow {
    margin-top: 0.5rem;
  }
  .adminVenBox {
    width: 600px;
    height: 70vh;
    overflow-x: overlay;
  }
  .adduserBox {
    width: 600px;
  }
  button.AddBtn.btn.btn-primary.btn-large {
    margin-left: 24px !important;
  }
  .label-name12 {
    margin-left: -15px;
    font-size: 18px;
  }
  .AddBtn {
    font-size: 18px;
  }
  .CancelBtn {
    font-size: 18px;
  }
  .changeModal {
    width: 300px;
    padding: 15px;
    height: 360px;
  }
  input#oldpassword {
    margin-bottom: 10px;
    height: 35px;
  }
  input#newpass {
    margin-bottom: 10px;
    height: 35px;
  }
  input#confirmpass {
    height: 35px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .modalclass {
    padding: 20px;
  }

  .modalclass1 {
    padding: 20px;
  }

  .input-borders {
    padding: 6px;
  }

  .viewName {
    font-size: 17px;
  }

  .viewBox {
    width: 350px;
    height: 360px;
    overflow-x: overlay;
  }

  .labelname {
    font-size: 17px;
  }

  .supprottxt {
    font-size: 17px;
  }

  .viewRow {
    margin-top: 0rem;
  }
  .adminVenBox {
    width: 400px;
    overflow-x: overlay;
  }
  .adduserBox {
    width: 400px;
  }

  button.AddBtn.btn.btn-primary.btn-large {
    margin-left: 24px !important;
  }
  .label-name12 {
    margin-left: -15px;
    font-size: 16px;
  }
  .AddBtn {
    font-size: 16px;
  }
  .CancelBtn {
    font-size: 16px;
  }
  .changeModal {
    width: 300px;
    padding: 15px;
    height: 360px;
  }
  input#oldpassword {
    margin-bottom: 10px;
    height: 35px;
  }
  input#newpass {
    margin-bottom: 10px;
    height: 35px;
  }
  input#confirmpass {
    height: 35px;
  }
  .modal-title {
    font-size: 21px !important;
  }
  .deletebox {
    width: 250px;
  }
  .delete-message {
    margin: 0.7rem !important;
    font-size: 16px !important;
  }
  .del_row {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .viewBox {
    width: 289px;
    height: 356px;
    overflow-y: auto;
  }

  .adduserBox {
    width: 300px;
  }

  .labelname {
    font-size: 16px;
  }

  .supprottxt {
    font-weight: 600;
    font-size: 16px;
  }

  .cnclCols {
    margin-top: -35px;
    margin-left: 145px;
  }

  .invoiceBox {
    width: 360px;
    height: 420px;
  }

  .rejctBox {
    height: 164px;
    width: 277px;
  }

  .rejectbtn.row {
    display: flex;
    flex-wrap: nowrap;
  }

  .modalclass {
    padding: 15px;
  }
  .modalclass1 {
    padding: 15px;
  }

  .cancel-btns {
    margin-right: 0%;
    height: 36px;
    font-size: 17px;
  }

  .add-btns {
    margin-right: 56%;
    height: 35px;
    padding: 0 40px;
    font-size: 17px;
    margin-top: 9px;
  }

  .label-name {
    font-size: 16px;
    font-weight: normal;
    margin-top: 6px;
  }

  .modal-title {
    font-size: 19px !important;
  }

  .form_row {
    margin-top: 8px;
  }
  .downloadIcon {
    width: 3rem !important;
  }
  .view {
    width: 3rem !important;
  }
  .viewName {
    font-weight: 600;
    font-size: 16px;
  }

  .viewRow {
    margin-top: 0rem;
  }

  button.AddBtn.btn.btn-primary.btn-large {
    margin-left: 24px !important;
  }
  .label-name12 {
    margin-left: -15px;
    font-size: 16px;
  }
  .AddBtn {
    font-size: 16px;
  }
  .CancelBtn {
    font-size: 16px;
  }
  .changeModal {
    width: 280px;
    padding: 15px;
    height: 343px;
  }
  input#oldpassword {
    margin-bottom: 10px;
    height: 30px;
  }
  input#newpass {
    margin-bottom: 10px;
    height: 30px;
  }
  input#confirmpass {
    height: 30px;
  }
  .pwd-container input {
    font-size: 18px;
  }
  .personalInfo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border: 0px solid #000;
    background-color: white;
    box-shadow: 10;
    width: 349px;
    padding: 20px;
    height: 485px;
  }

  .personalCncl {
    background-color: #0bb1d3;
    border: none;
    padding: 7px 22px;
    margin-left: 96px;
    margin-top: 15px;
    color: white;
  }
  
  .adminVenBox {
    width: 320px;
    overflow-x: overlay;
  }
  .delete-message {
    font-size: 16px !important;
    margin: 0.5rem !important;
  }
  .del-hr {
    margin-left: 75px;
  }
  .del-logo {
    margin-left: 7rem;
  }
  .deletebox {
    width: 224px;
  }
  .del_row {
    margin-top: 0.9rem;
  }
  .del-add {
    font-size: 18px;
  }
  .del-cncl {
    font-size: 18px;
  }
  .ppendingbox {
    width: 300px;
  }
}

.tannumcol{
  margin-bottom: 10px !important;
}
.tannumlabel{
  margin-bottom: 10px !important;
}

.addbank-input-div{
  height: 40px
}

.bankmodaldropdown{
  height: 35px;
  width: 300px;
  border-radius: 5px;
  outline-color:black!important; 
  border-color: #D1DADD;
}
.inp-bankname{
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}
.csvfile-input{
  box-shadow: 0px 0px 2px #555;
  border-radius: 5px;
  width: 300px;
  outline-color:black!important; 
  border-color: #D1DADD;
}
.csvfile-input::file-selector-button{
  border-radius: 5px;

}
.flexrowaddbank{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.lblbank{
  height:42px;
  display:flex;
  align-items: center;
}
.admindoc{
  height: 50px !important;
  min-height: 50px !important;
 overflow-y: scroll;
 margin-top:10px;
}

.inconsdiv{
  display: flex;
  align-items: center;
  grid-gap: 0px 2px;
  gap: 0px 2px;
}

.edit-vendor-form-container {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}
.table  {
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  
}

.tableC-financePaidModal{
  border-radius: inherit;
  max-height: 150px;
  overflow: scroll;
  padding: 20px;
}

.table-financePaidModal{
  width: 100%;
}
.tableHead {
  display: table-header-group;
}

.tableRow {
  display: table-row-group;
  margin: 20px 0;
}

.admintitle {
  font-size: 22px;
}

.userfilter-btns {
  text-align: end;
  padding-right: 20px;
}

.disableClick {
  cursor: default;
}

/* modal design */

.date-input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #a2afb4;
}

.filterBox {
  position: absolute;
  top: 69rem;
  left: 167rem;
  -webkit-transform: translate(-50rem, -50rem);
          transform: translate(-50rem, -50rem);
  width: 300px;
  height: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

/*------------------------*/
.select-box {
  margin-top: 10px;
  border: 1px solid #a2afb4;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  outline: none;
}

.modal-btn {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
}

/* @media screen and (max-width: 600px) {
} */

.filter-pop {
  padding: 20px;
}

.popBox {
  border: 1px solid white;
  padding: 20px;
}

.table-border {
  border: 1px solid rgba(13, 202, 240, 0.3);
  border-radius: 1.5rem;
  padding-left: 32px;
  padding-right: 32px;
}

.space {
  margin-top: 2%;
}

.statusdata {
  margin: 25px 0;
}
.countdata {
  margin: 25px 0;
}

.makeStyles-drawerPaper-6 {
  width: 15%;
  overflow-x: clip;
}

.makeStyles-root-5 {
  display: flex;
  z-index: 0;
  padding-top: 24px;
}

.isDisabled {
  pointer-events: none;
  opacity: 0.8;
}

.enabled {
  color: #0dcaf0 !important;
  cursor: pointer;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  font-size: 22px;
  color: black;
  background-color: inherit;
  border-bottom: 4px solid #0dcaf0;
  opacity: 1;
}

.nav-pills .nav-link {
  font-size: 22px;
  border-radius: 0.25rem;
  color: black;
  opacity: 0.4;
}

/* .appracp{
    border: 2px solid red;
    margin-left: 20%;
    
}

.apprrej{
    border: 2px solid red;
    margin-left: 319%;
} */

/* .table-link vendorview{
    font-size: 50px!important;
} */

span.table-link.vendorview {
  font-size: 18px;
}

span.financeUser {
  color: black;
  font-size: 17px;
}

.vendorList {
  color: black;
  font-size: 18px;
}

.vendorUserLi {
  color: black;
  font-size: 18px;
}

/* .MuiBox-root-125 {
    height: 80%;
} */

.counts {
  cursor: pointer;
}

.permission-row {
  margin: 20px 0 !important;
  display: grid;
  border: 1px solid #0dcaf0;
  border-radius: 10px;
  padding: 10px;
}

.role-title {
  align-self: start;
  justify-items: start;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px;
  align-items: center;
  align-content: center;
  padding: 1rem 0;
}

.switch-block {
  display: grid;
}

.permit {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0.5em;
  grid-row-gap: 0.6em;
  justify-items: start;
  grid-auto-columns: auto;
}

.permit-label {
  font-size: 15px;
  margin-left: 5px;
}

.entity-title {
  font-weight: bold;
}

.admin-label {
  padding-left: 5px;
}

.permission-div {
  margin-right: 50px;
}

.permit-save-row {
  display: grid;
  align-items: center;
  justify-items: center;
}

.permit-btn {
  background-color: #0bb1d3;
  color: white;
  border: none;
  height: 3.5rem;
  width: 10rem;
  font-size: 20px;
}

.roles-row {
  margin: 17px 0px !important;
}

span > input {
  height: 0.7em;
  width: 0.8em;
}

.dropdownbox {
  border-radius: 5px;
  width: 20%;
}

.role-btns {
  width: 10%;
  background: #0dcaf0;
  border: none;
  color: white;
  height: 30px;
}

.datecurve{
  border-radius: 20px;
  border: 1px solid #dfeae6;
  padding: 0.8rem 1.2rem;
  color: black;
  width: 31%;
  outline: none;
  font-size: 18px;
  height: 40px;
}

.back-styles {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.arrow-back-icon {
  font-size: 20px !important;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .permit {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .statusdata {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .permit {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .statusdata {
    display: grid;
  }

  .admintitle {
    font-size: 20px;
  }

  span.table-link.vendorview {
    font-size: 14px;
  }

  .nav-pills .nav-link {
    font-size: 18px;
    padding: 0.2rem 0.6rem;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    font-size: 18px;
    padding: 0.2rem 0.6rem;
  }
  .permit {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: none;
  }
  .countdata {
    display: grid;
  }
}

.archiveHeader {
  display: flex;
  justify-content: space-between;
}

.toolTip{
  font-size: 16px !important;
  padding: 5px;
  word-wrap: break-word;
}
.tooltip-desc{
  height: 170px !important;
overflow-y: hidden;
border-radius:5px;
font-size: 16px !important;
}

.tooltip-desc{
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tooltip-desc::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.tooltip-desc{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.text-totalamount{
  color: black;
}
.tannumalign{
 text-align: left;
 padding-left: 15px !important;
}
.space1{
padding-left: 5px !important;

}
.red{
  color:red !important;
}

.btnparttext{
display:flex !important;
justify-content:space-between
}
.closeinpartialPayModal{
  height:30px;
  /* width: 10px; */
}


.btnproceed{
    background-color: #0dcaf0;
    color:white !important;
    outline:none;
   margin-left:9px;
   width:100px;
   border-radius: 5px !important;
}
.btnproceed:hover{
    opacity: 0.7;
}
.proceeinvoiceBtn{
    text-align: right;
}

.paramount{
  padding-left: 12px !important;
}
.fullamount{
  padding-left: 12px !important;
}
.descfinancerInv{
  padding-left: 12px !important;
}
.statusfinancerInv{
  padding-left: 12px !important;
}
.totalamountFinInv{
  padding-left: 12px !important;
}
.tdsamountFinInv{
  padding-left: 12px !important;
}
.gstamtFinInv{
  padding-left: 12px !important;
}
.dateFinInv{
  padding-left: 11px !important;
}
.downloadcsv{
  height:30px;
  width:30px;
}
.disablerejectpay{
  color:#B3E7FE;
}
.error-bankname{
  color: red;
  font-size: 14px;
}
.label-name90{
  text-align: left;
  font-weight: bold;
}
.panels-title {
    font-size: 25px;
    font-weight: bold;
    padding-left: 25px;
}

.nimapLogo {
    height: 45px;
    margin-left: -12px;
}

.nav-main {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 2fr;
    padding: 7px 0;
}

.bg-light2 {
    padding-left: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.drawericon {
    padding-right: 15px;
    align-self: center;
    margin-top: 6px;
}

.text-div {
    padding-top: 5px;
    text-align: left;
}

.search-box {
    border-radius: 20px;
    border: 1px solid #dfeae6;
    padding: 0.8rem 1.2rem;
    color: black;
    width: 100%;
    outline: none;
    font-size: 18px;
}

.fa-bell-o {
    margin-right: 2rem;
}


.logoImg img,
.profilePhoto i,
.profilePhoto img {
    cursor: pointer;
}

.form-control:focus,
*:focus {
    box-shadow: none !important;
}

button.personal-information-modal-content-button {
    cursor: pointer;
    margin-left: 24px;
}

.MuiMenu-paper {
    max-height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;
    width: 200px;
    background-color: #f7fcff !important
}

.page-link:hover {
    z-index: 2;
    background-color: #4bcbf0 !important;
    color: #dee2e6 !important;
}

.navbar-class {
    padding-left: 24px !important;
    padding-top: 16px;
}

.name-text {
    padding-right: 23px;
    /* padding-top: 10px; */

}

.tr {
    border-bottom: 1px solid;
    height: 86px;
    overflow-x: inherit;

}

.border {
    border-bottom: 1px solid;
    display: grid;
    z-index: 0;
}

.user-name {
    padding-top: 10px;
    margin-left: 10px;
    text-align: left;
    font-size: 22px;
}

.profile-icon {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.logo {
    font-size: 4rem !important;
    align-self: center;
    justify-self: end;
}

.personalTab {
    font-size: 18px !important;
}

.personal-txt {
    color: black;
}

.personal-txt:hover {
    color: black;
}
.menuBox{
    top: 40px !important;
    left:30px !important;
}

@media screen and (min-width:1025px) and (max-width:1200px) {
    .panels-title {
        font-size: 22px;
        font-weight: bold;
        padding-left: 25px;
    }

    .nav-main {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 2fr;
        padding: 7px 0;
    }

    .user-name {
        padding-top: 10px;
        margin-left: 10px;
        text-align: left;
        font-size: 18px;
    }
}


@media screen and (min-width:769px) and (max-width:1024px) {
    .nav-main {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 3fr;
        padding: 7px 0;
    }

    .user-name {
        padding-top: 10px;
        margin-left: 10px;
        text-align: left;
        font-size: 18px;
    }

    .panels-title {
        font-size: 22px;
        font-weight: bold;
        padding-left: 25px;
    }

    .profile-icon {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
    }
    .menuBox {
        top: 40px !important;
        left: 9px !important;
    }
    .personalTab {
        font-size: 16px !important;
    }
}

@media screen and (min-width:481px) and (max-width:768px) {
    .nav-main {
        grid-template-columns: 1fr 6fr 1fr;
    }

    .bg-light2 {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .panels-title {
        font-size: 22px;
        font-weight: bold;
        padding-left: 25px;
    }

    .profile-icon {
        display: grid;
        grid-template-columns: 1fr;
        grid-column: 3/4;
        grid-row: 1/2;
    }

    .text-div {
        display: none;
    }

    .searchCol {
        grid-column: 2/3;
        grid-row: 1/2;
        display: grid;
        grid-template-columns: 1fr;
    }

    .search-box {
        width: 22rem;
        justify-self: end;
        padding: 0.2rem 0.8rem;
        font-size: 18px;
    }

    .logo {
        padding-left: 10px;
    }

    .user-name {
        display: none;
    }

    .nimapLogo {
        height: 40px;
        width: 15rem;
    }

    .table-title {
        font-size: 20px;
    }
    
    .MuiMenu-paper {
        width: 160px;
    }
    .MuiMenuItem-root {
        min-height: 32px !important;
    }
    .menuBox {
        top: 40px !important;
        left: 9px !important;
    }
    .personalTab {
        font-size: 14px !important;
    }
}

@media screen and (min-width:320px) and (max-width:480px) {
    .nav-main {
        grid-template-columns: 1fr 3fr 1fr;
    }

    .bg-light2 {
        padding-left: 12px;
    }

    .text-div {
        display: none;
    }

    .user-name {
        display: none;
    }

    .nimapLogo {
        height: 2.5rem;
        width: 12rem;
    }

    .search-box {
        padding: 0.2rem 0.8rem;
        font-size: 16px;
    }

    .profile-icon {
        grid-template-columns: 1fr;
    }

    .logo {
        font-size: 3rem !important;
    }

    .table-title {
        font-size: 20px;
    }

    .MuiMenu-paper {
        width: 160px;
    }

    .personalTab {
        font-size: 14px !important;
    }
    .menuBox {
        top: 40px !important;
        left: 12px !important;
    }
    .MuiMenuItem-root {
        min-height: 32px !important;
    }
}
.classes .form {
    background: white;
}

.nimaplogo {
    height: auto;
    width: 180px;
    padding-top: 7px;
    margin-left: -10px;
}

.text {
    background: "white"
}

.testLink {
    border-right: 5px solid #00a8ff;
    border-radius: 5px;
    color: black;
    font-weight: bold;
}

.arrowbtn {
    margin-left: -10px;
}

.input-border {
    border-radius: 0.4rem !important;
}

.size {
    font-size: 20px;
}

.test:focus {
    font-size: 35px !important;
    color: #f7fcff !important;
    font-weight: bold;
}

.side-list .MuiTypography-body1 {
    font-weight: bold;
    font-size: 15px;
}

.MuiTypography-displayBlock {
    display: block;
    font-size: 18px !important;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px solid rgba(0, 0, 0, 0.12);
    background-color: #f7fcff !important;
}

.side-menu {
    color: #ccebf1;
    margin-right: -22px;
    width: 25px;
    height: 25px;
}

.side-menulist {
    color: #ccebf1;
    margin-left: -4px;
    width: 35px;
    height: 30px;
}

.side-menus {
    color: #ccebf1;
    margin-left: -37px;
    width: 35px;
    height: 30px;
}

.listIcon {
    padding-left: 6rem;
}

.menu-item {
    background-color: #0dcaf0;
    height: 100% !important;
    text-align: left,
}

.toolbarTest {
    min-height: 204px !important;
}

.sidebar-name:hover {
    color: black !important;
    text-decoration: none;
}

.disableTab {
    cursor: not-allowed
}

.enableTab {
    pointer-events: all;
}

.sidebar-name {
    width: 239px !important;
    font-size: 25px !important;
}

.MuiPaper-root.MuiDrawer-paper.jss6.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
    border: none;
}

.makeStyles-drawerPaper-6 {
    width: 16.35% !important;
    border: hidden !important;
}

img {
    vertical-align: middle;
    margin-left: -27px;
    width: 180px;
}

.arrow {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.main-transition {
    transition: 5s !important;
}

.side-list-finance-text {
    font-size: 18px;
}

.expanded-submenu {
    display: block;
    width: 175px;
    font-size: 17px;
    margin-top: -10px;
    text-align: left;
    margin-left: 45px;
    color: #000;
}
.financer-submenu{
    margin-top: -17px;
}
.expand-icon {
    margin-left: -30px;
}

.finance-submenu-header {
    margin-top: -7px;
}
.navMain{
    padding: 0px !important;
  } 
@media screen and (min-width: 600px) and (max-width: 1279.95px) {
    img {
        width: 148px;
    }
    .nimaplogo {
        height: auto;
        width: 140px;
        padding-top: 0px;
        margin-left: -12px;
    }
    .MuiTypography-displayBlock {
        font-size: 17px !important;
    }
    .side-menus {
        width: 32px;
        height: 28px;
    }
    .sidebar-name {
        width: 219px !important;
    }
    .MuiListItem-root {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 599.95px) {
    .MuiTypography-displayBlock {
        font-size: 16px !important;
    }
    .side-menus {
        width: 27px;
        height: 21px;
    }
    .MuiListItem-root {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }
    .sidebar-name {
        width: 192px !important;
    }
    .nimaplogo {
        height: auto;
        width: 140px;
        padding-top: 0px;
    }
    img {
        width: 144px;
    }
    .side-menulist {
        width: 30px;
        height: 27px;
    }
}

@media screen and (min-width:320px) and (max-width:481px) {
    .listIcon {
        padding-left: 5rem;
    }

    .sideopen {
        -webkit-transform: translateX(-240px);
                transform: translateX(-240px);
        visibility: hidden;
        transition-property: width;
        transition-duration: 2s;
        transition-timing-function: linear;
        transition-delay: 1s;
    }

    .sideclose {
        -webkit-transform: 0px;
                transform: 0px;
        transition-property: width;
        transition-duration: 2s;
        transition-timing-function: linear;
        transition-delay: 1s;
    }
}

.admin-notification{
height:20px !important;
width:20px !important;
border-radius: 5px !important;
background-color: #0DCAF0;
color:#000;
display: flex;
align-items: center;
justify-content: center;
align-content: center;
font-size: 17px !important;
margin-right: 5px!important;
padding-top: 1px !important;
}

.approver-notification{
    height:20px !important;
    width:20px !important;
    border-radius: 5px !important;
    background-color: #0DCAF0;
    color:#000;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-size: 17px !important;
    margin-right: 5px!important;
    padding-top: 1px !important;
    }

    .venreqsidebarBtn{
        border-right: 6px solid !important;
        height: 100% !important;
        color: #0dcaf0 !important;
        border-radius: 5px !important;
        width: 100% !important;
        display:flex !important;
        justify-content:center !important;
        align-items:center !important;
    }
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

::-webkit-scrollbar-thumb {
  background-color: #61dafb;
  border-radius: 0.65rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.color-black {
  color: black;
}

.range1 {
  color: #f1270d;
}
.range2 {
  color: #ffa500;
}
.range3 {
  color: #179f17;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toastsContainer {
  margin-top: 5%;
  right: 5%;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.text {
  background: "white";
}

.text-left {
  padding-top: 20px;
  text-align: left;
}

.text-right {
  text-align: right;
}

.tex-center {
  text-align: center;
}

.sidebar-bg {
  background-color: #fff;
}

.form-control {
  box-shadow: 0px -1px 0px 0px rgb(0 0 0 / 37%);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

HTML,
BODY,
#root {
  height: 100%;
}

/*Pagination css*/
.pageContainer {
  margin-top: 15px;
}

.selectLabel {
  font-size: 18px;
}

.paginationBtns {
  width: 100%;
  height: 30px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.paginationBtns a {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #0dcaf0;
  color: #0dcaf0;
  cursor: pointer;
  text-decoration: none;
}

.paginationBtns a:hover {
  color: white;
  background-color: #0dcaf0;
}

.paginationActive a {
  color: white;
  background-color: #0dcaf0;
}

.reset-text {
  margin-left: 0;
}

.AddFiles {
  background: none;
  border: none;
}
.AddFiles-button {
  background: none;
  border: none;
  position: absolute;
  right: 0%;
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .selectLabel {
    font-size: 14px;
  }

  .paginationBtns {
    font-size: 14px;
  }
  .toastsContainer {
    margin-top: 7% !important;
    margin-left: 45% !important;
    width: 34% !important;
    font-size: 16px;
  }
  .Toastify__toast {
    min-height: 0% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .selectLabel {
    display: none;
  }

  .rows-select {
    padding: 3.2px 0px;
    font-size: 14px;
  }

  .paginationBtns a {
    padding: 2px 8px;
  }
  .toastsContainer {
    margin-top: 17% !important;
    margin-left: 45% !important;
    width: 54% !important;
    height: 5px !important;
    font-size: 14px;
  }
  .Toastify__toast {
    min-height: 0% !important;
  }
}

.custom-file-label {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.9;
}
.input-area-uploadinvoiceadmin{
  margin-top: 12px !important;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
  background-color: #f8f9fa !important;
  background-size: auto;
}

.login {
  width: 100%;
  height: 100vh;
}

.forgot-outer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.login-1 {
  grid-column: 2/4;
  margin-top: 18rem;
  padding: 0 3rem;
}

img {
  width: 190px;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: black;
  font-weight: 500;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: black;
}

/* input {
  border-radius: 10px;
} */

h2,
h4,
h5 {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
  text-align: center;
  width: 600px;
  font-size: 30px;
  margin: 10px 0;
}

h6 {
  font-size: 20px;
  margin: 25px;
  font-weight: 700;
}

body,
html,
.custom-control-label {
  font-weight: 400;
}

.rem {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.specs {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  text-align: center;
  font-size: 20px;
  outline: none;
  border: 1px solid rgb(192, 191, 191);
}

.form-group {
  width: 375px;
}

.admin {
  display: flex;
  flex-direction: column;
  margin: 50px;
  padding: 50px;
  background-color: #fff;
  border-radius: 15px;
}

.admin1 {
  color: #000 !important;
}

p {
  text-align: center;
  font-size: 20px;
}

.modal-content {
  border-radius: 20px !important;
  margin: 120px;
}

th {
  overflow-x: auto;
}

button {
  box-shadow: none !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  outline-style: none !important;
}

.modal-body {
  margin: 30px !important;
}

label {
  font-weight: 500;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #64bcec !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: grey !important;
}

.MuiAppBar-colorDefault {
  background-color: #fff !important;
  box-shadow: none !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #64bcec !important;
  height: 5px !important;
}

.MuiTab-wrapper {
  text-align: left !important;
  align-items: flex-start !important;
}

.PrivateTabIndicator-root-1 {
  width: 50px !important;
  margin-left: 12px !important;
}

.MuiTableCell-root {
  padding-right: 30px !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  font-size: 15px !important;
}

.client {
  margin: 70px;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  grid-gap: 50px;
}

.search {
  padding: 13px !important;
  width: 100%;
  outline: none !important;
}

.MuiOutlinedInput-input {
  padding: 15px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #64bcec !important;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiSelect-selectMenu:focus {
  background-color: #fff !important;
}

.MuiSelect-selectMenu {
  background-color: #fff !important;
}

.MuiSelect-icon {
  color: #64bcec !important;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.MuiSelect-outlined.MuiSelect-outlined {
  outline: none !important;
  border: none !important;
}

#dropdown {
  color: black;
  background-color: transparent;
  border: none;
  font-size: 25px;
}

.dropdown-menu.show {
  width: 100%;
  border-radius: 5px;
  margin: 0px;
}

.nimap-logo {
  width: 21rem;
  height: 10rem;
  justify-self: center;
}

.forgotForm {
  justify-self: center;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

/* 
.inputcol {}

.btnCol {} */

.projectName {
  font-size: 30px;
  color: #64bcec;
  margin-top: 5px;
}

.formTitle {
  font-size: 25px;
}

.forgot-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 17px;
  font-family:inherit;
}

.MuiInputBase-input{
font-size: 17px !important;
}
.submit-btn {
  color: white;
  background-color: #64bcec;
  width: 100%;
  border: none;
  font-size: 15px;
  padding: 6px 0px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .nimap-logo {
    width: 18rem;
    height: 8rem;
  }

  .login-1 {
    padding: 0rem;
  }

  .projectName {
    font-size: 26px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .formTitle {
    font-size: 22px;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .login-1 {
    grid-column: 2/5;
    margin-top: 38rem;
  }

  .forgot-outer {
    grid-template-columns: repeat(5, 1fr);
  }

  .formTitle {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .projectName {
    font-size: 22px;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .nimap-logo {
    width: 16rem;
    height: 6rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .formTitle {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .projectName {
    font-size: 22px;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .nimap-logo {
    width: 16rem;
    height: 6rem;
  }

  .login-1 {
    grid-column: 2/6;
    margin-top: 30rem;
  }
}

.tableHead {
  display: table-header-group;
}

.search_container{
  width: 800px;
  margin: auto;
  margin-top: 70px;

}
.search_box , .close_icon{
  position: relative;
  padding: 12px;

}
.search_box
{
  width: 90%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 18px;

}
.close_icon{
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;


}

.close_icon::after{
  
  width: 20px;
  height: 20px;
  position: absolute;
  background-color:red;
  right:30px ;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 5px;
  font-weight: normal;
  font-size: 14px;
  box-shadow: 0 0 2px cyan;
  cursor: pointer;

}
.search_box:not(:valid) ~ .close_icon{
  display: none;
}
.minusicon {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.crossicon {
  height: 20px;
  width: 20px;
  border: none;
  margin-top: 10px;
  margin-left: 10px;
  /* border:1px solid black; */
}

.tableRow {
  display: table-row-group;
}



.cols1{
 
  margin: 0px;
  padding-right: 0%;
  
}

.cols2{
  
  
  padding-left: 0%;
 
}

.cols3{
  
  margin: 0px;
  padding-right: 0%;
}

.cols4{
  padding-left: 0%;
 
}

.filtertitle {
  font-size: 20px !important;
  text-align: left;
}

.userfilter-btns {
  text-align: end;
  padding-right: 20px;
}

.filter-btn {
  text-align: end;
  padding-right: 36px;
}

.overdue_high {
  color: red;
}

.overdue_low {
  color: #0eff15;
}

.date-input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #a2afb4;
}

.select-box {
  margin-top: 10px;
  border: 1px solid #a2afb4;
  border-radius: 10px;
  width: 100%;
  padding: 1rem;
  outline: none;
}

.modal-btn31 {
  background-color: #0dcaf0;
  border-radius: 15rem;
  color: white;
  border: none;
  height: 37px;
  width: 100%;
  font-size: 20px;
}

.invfilter {
  position: fixed;
  top: 49%;
  left: 85%;
  width: 35rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  padding: 2.5rem;
}

.backgroundColors {
  margin-top: 45px;
  background-color: #0dcaf0;
  color: white;
  border: none;
  height: 37px;
  width: 120px;
  font-size: 20px;
}

.filter-pop {
  padding: 20px;
}

.firstcol {
  text-align: right;
}

.secondcol {
  text-align: left;
}

.button {
  background-color: #0dcaf0;
  border-radius: 0.8rem;
  color: white;
  padding: 06px 04px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.bg-light {
  height: 74px;
}

.userClass {
  padding-right: 25px;
  padding-bottom: 2px;
}

.table-border {
  border: 1px solid rgba(13, 202, 240, 0.3);
  border-radius: 1.5rem;
  padding-left: 32px;
  padding-right: 32px;
}

.table -width {
  width: 97% !important;
}

.table-header-custom {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: left;
}

.wrapper {
  text-align: center;
}

.subHeading {
  display: inline-block;
  width: "35%";
}

.width {
  width: 150px;
}

.bigpartist {
  width: 100% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
}

.partist {
  /* width: calc(100vw - 225px) !important; */
  width: 81.5% !important;
  float: right;
  transition: 0.5s;
  overflow-x: hidden !important;
}

.makeStyles-drawerPaper-6 {
  width: 15%;
  overflow-x: clip;
}

.makeStyles-drawerPaper-10 {
  width: 15%;
  /* overflow-x: clip; */
}

.hr-modal {
  height: 6px !important;
  width: 39px;
}

.w-25 {
  width: 25%;
}

.jZkBQk .wrapper {
  padding: 0 !important;
}

.makeStyles-root-5 {
  display: flex;
  z-index: 0;
  padding-top: 24px;
}

.invoices-top-table {
  margin-bottom: -10px;
  padding-right: 30px;
  margin-top: 2rem;
}

.invoice-bottom-table22 {
  padding: 0px 20px;
}

.inr-symbol {
  margin-bottom: -4px;
  font-size: 17px;
}

h2#modal-modal-title {
  margin-left: -119%;
}

.sidebarsss {
  margin-top: 400px !important;
}

.actionbtn {
  visibility: hidden;
  cursor: initial;
}

.inputbox {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}

.inv-label {
  text-align: left;
  display: block;
  font-size: 16px;
}
.inv-label1 {
  text-align: left;
  display: block;
  margin-top: 5px;
  font-size: 13px;
  color: #0dcaf0;
 
}
.inv-label3 {
  text-align: left;
  display: block;
  font-size: 13px;
  color: #0dcaf0;
 
}
.inv-label2 {
  margin-top: 6px;

  text-align: left;
  display: block;
  font-size: 13px;
  color:red;
 
}
.inv-label4 {
  margin-top: 18px;

  text-align: left;
  display: block;
  font-size: 13px;
  color:red;
 
}



.doc-label {
  text-align: left;
  display: block;
  margin-top: 10px;
  margin-bottom: 0;
}



.row_space {
  padding: 0 99px;
}
.row_space1 {
  padding: 0 99px;
}
.row_space2 {
  padding: 0 99px 15px;
}

.doctwo {
  margin-top: 39px !important;
}

.editlink {
  color: #0dcaf0;
}

.editlink:hover {
  color: #0dcaf0;
}

.invoice-form {
  margin-top: 14px;
  padding-bottom: 30px;
}

.registerPage {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.leftside {
  background-image: url(/static/media/register.f8a90197.png);
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.rightside {
  padding: 3rem 5rem 0;
}

.register-img {
  height: 100vh;
  width: 100%;
}

.captcha-row {
  margin: 0.5em 0;
}

.form-col {
  padding: 5rem;
}

.reg-rows {
  padding-top: 10px;
}

.regbtn-rows {
  padding-top: 30px;
  justify-content: center;
}

.regsbmtbtn {
  background-color: #0dcaf0;
  color: white;
  border: none;
  padding: 12px 41px;
  font-size: 18px;
}

.regfield-col {
  text-align: left;
}

.reg-label {
  font-size: 16px;
  font-weight: 100;
  text-align:"left";
  width:"100%"
}

.reg-input {
  display: grid;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #b7c4c9;
}

.reg-input::-webkit-input-placeholder {
  font-size: 0.5em;
}

.reg-input::placeholder {
  font-size: 0.5em;
}

.headrows {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .partist {
    width: 75% !important;
  }

  .userfilter-btns.col-lg-9 {
    margin-top: -107px;
  }

  .invfilter {
    position: fixed;
    top: 29rem;
    left: 57rem;
    width: 27rem;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }

  .filtertitle {
    font-size: 18px !important;
    text-align: left;
  }

  .date-input {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding: 0.3rem;
    border-radius: 5px;
    border: 1px solid #a2afb4;
  }

  .filterRow.row {
    display: flex;
    flex-wrap: nowrap;
  }

  .backgroundColors {
    margin-top: 45px;
    background-color: #0dcaf0;
    color: white;
    border: none;
    height: 37px;
    width: 129px;
    font-size: 18px;
  }

  .invoice-bottom-table22 {
    padding: 0px 5px;
  }

  .row_space {
    padding: 0 15px;
  }
  .row_space1 {
    padding: 0 15px;
  }
  .row_space2 {
    padding: 0 15px 5px;
  }

  .cols1{
    
    margin: 0px;
    padding-right: 0%;
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .partist {
    width: 96.5% !important;
  }

  .invoice-bottom-table22 {
    padding: 0px 5px;
    margin-top: 1px;
  }

  .userfilter-btns.col-lg-9 {
    margin-top: -106px;
    margin-right: 0px;
  }

  .invfilter {
    position: fixed;
    top: 31rem;
    left: 27rem;
    width: 27rem;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }

  .filtertitle {
    font-size: 16px !important;
    text-align: left;
  }

  .backgroundColors {
    margin-top: 45px;
    background-color: #0dcaf0;
    color: white;
    border: none;
    height: 35px;
    width: 46px;
    font-size: 16px;
  }

  .row_space {
    padding: 0 34px;
  }
  .row_space1 {
    padding: 0 34px;
  }
  .row_space2 {
    padding: 0 34px 10px;
  }
  .cols1{
    
    margin: 0px;
    padding-right: 0%;
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
}

@media screen and (min-width: 481px) and (max-width: 575px) {
  .partist {
    width: 96.5% !important;
  }

  .invoice-bottom-table22 {
    padding: 0px 5px;
    margin-top: 1px;
  }

  .userfilter-btns.col-lg-9 {
    margin-top: -106px;
    margin-right: 0px;
  }

  .invfilter {
    position: fixed;
    top: 31rem;
    left: 27rem;
    width: 27rem;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 20px;
    padding: 2rem;
  }

  .filtertitle {
    font-size: 16px !important;
    text-align: left;
  }

  .backgroundColors {
    margin-top: 45px;
    background-color: #0dcaf0;
    color: white;
    border: none;
    height: 35px;
    width: 46px;
    font-size: 16px;
  }

  .row_space {
    padding: 0 34px;
  }
  .row_space1 {
    padding: 0 34px;
  }
  .row_space2 {
    padding: 0 34px 10px;
  }
  .cols1{
    
    margin: 0px;
    padding-right:0%;
    
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
  .crossicon {
    height: 20px;
    width: 20px;
    border: none;
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  .bigpartist {
    width: 99% !important;
  }

  .partist {
    width: 99% !important;
  }

  .invoice-bottom-table22 {
    padding: 0px 1px;
  }

  .inv-label {
    margin-top: 5px;
    font-size: 16px;
  }

  .inv-label1 {
    margin-top: 5px;
    font-size: 13px;
  color: #0dcaf0;
  }
  .inv-label3 {

    font-size: 13px;
  color: #0dcaf0;
  }
  .inv-label2 {
    margin-top: 10px;
    font-size: 13px;
  color: red;
  }
  .inv-label4 {
    margin-top: 18px;
    font-size: 13px;
  color: red;
  }

  .inputbox {
    padding: 2px;
    margin-top: 4px;
  }

  .row_space {
    padding: 0px 10px;
    display: grid;
  }
  .row_space1 {
    padding: 0px 10px;
    display: grid;
  }
  .row_space2 {
    padding: 0px 10px 2px;
    display: grid;
  }
  .cols1{
    
    margin: 0px;
    padding-right: 0%;
  }
  
  .cols2{
    
    
    padding-left: 0%;
   
  }
  .crossicon {
    height: 20px;
    width: 20px;
    border: none;
    margin-top: 15px;
    margin-left: 10px;
  }
}

.tooltip-desc{
  height: 170px !important;
overflow-y: hidden;
border-radius:5px;
font-size: 16px;
}

.tooltip-desc{
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tooltip-desc::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.tooltip-desc{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.vendorrow{
  margin-bottom: 4px !important;
}
.vendorprofilegstdoc{
  text-align: left !important;
/* border:1px solid black; */
  width: 100%;
}
.pandocument{
  text-align: left !important;
/* border:1px solid black; */
  width: 100%;
}
.tannumber-row{
  margin-top: 10px;
}
.tan-num{
  margin-top: 7px;
}

.error-text-vendor-form{
color:red !important;
font-size: 14px !important;
}

.leftmr{
  text-align: left;
}
.red{
  color:red;
}
.mandatory {
  color: red;
}

.venusername{
color:red !important;
font-size: 14px !important; 
}

.formadmincreateinv{
  /* border: 1px solid#0DCAF0 !important; */
  border-radius: 20px;
  margin-bottom: 2%;
  width:100%;
  background-color: #fff;
  box-shadow: 0 0 5px #999;
  margin-top: 20px;
}

.table-title{
  font-family: 'Geologica', sans-serif;
font-family: 'Work Sans', sans-serif;
}


.form_invoice_row{
  margin-top: 10px;
}
.form_invoice_row-bannkaccnum{
  margin-top: 19px;
}
.form_invoice_row-baseamount{
  margin-top: 24px;
}
.uploadinvcol{
  margin-top: 30px;
}
/* .widthss{
  padding: 0px 90px;
} */
.admincreinv-container{
  padding: 0px 90px;
}
.uploadinvoicetitile{
font-weight: 600;
}

.admin_create_invoice_bottomborder {
  background-color: #0dcaf0;
  border-radius:10px;
  color: white;
  border: none;
  height: 5px !important;
  margin: 0 142px 15px 0;
  width: 60px !important;
}
.form_row-support{
  margin-top: 14px;
}

.form_row-plus{
  margin-top:16px;
}

.mandatory {
  color: red;
}
.uploadinvoiceadmin{
  font-size: 15.5px;
}
.doc-label{
  font-size: 15.5px;
}
.crossdiv{

 display:flex;
 align-items: center;
 justify-content: center;

}

.input-area-uploadinvoiceadmin{
  margin-top: 0px !important;
  max-height: 11.9rem !important;
    min-height: 11.9rem!important;
}
.vendordescription{
  max-height: 11.9rem !important;
  min-height: 11.9rem!important;
}
.bnkaccinp{
  background-color:#D3D3D3 !important;
}
.ifscinp{
  background-color:#D3D3D3 !important;
}
.tdsinp{
  background-color:#D3D3D3 !important;
}
.accnosubtext{
  font-size: 13.2px;
  margin-left:7px;
}
.venreg-iframe{
  pointer-events: auto;
}
.main-div{
  padding-top:22%;
}
.pleasewaittext{
    font-size: 25px;
    color: gray;
    font-family: 'Sora', sans-serif;
    text-align: center;
}
.loaderimg{
    height: 100px;
    width: 100px;
}
